module.exports = {
    $:'$',
    head_t1:'烏克蘭政府的官方網站',
    gengduo_yd:'閲讀更多',
    fenxiang_wz:'分享網站',
    zong_sj:'縂收集額',
    xiangmu:'項目',
    dashi:'大使',
    liji_jz:'立即捐贈',
    shipin_cw:'此視頻無法播放，請稍後再試',
    banner_t1:'烏克蘭總統的倡議',
    banner_t2:'烏克蘭官方籌款平台',
    guank_sp:'觀看視頻',
    zhichi_wm:'我們的支持者',
    zuixin_xm:'最新項目',
    juanz_lx:['防禦','教育與科學','人道主義','醫療援助'],
    suoyou_xm:'显示所有项目',
    report_t1:'透明和效率',
    report_t2:'ZEROAGENCY 每周提供五个方向的基金分配报告',
    report_t3:'来自世界各地的人们团结起来，愿意帮助乌克兰。ZEROAGENCY目标是增加对乌克兰的捐款，并确保其分配的效率和透明度。',
    guanyu_wz:'关于ZEROAGENCY',
    shouji_zs:'收集总数',
    juanz_zj:'直接捐赠',
    baogao_qw:'报告全文',
    dashi_wm:'我们的大使',
    suoyou_dsxs:'显示所有大使',
    fabu_sj:'在社交媒体上发布',
    fenx:'分享',
    dingy_t1:'订阅每周实时通讯',
    dingy_t2:'订阅ZEROAGENCY报告、新闻和更新',
    fenx_p:['脸书','油管','电报'],
    dingye:'订阅',
    shuru_yx:'输入您的电子邮箱',
    zuixin_xx:'最新消息',
    suoyou_xwxs:'显示所有新闻',
    zhichi_t1:'在这个关键时刻支持乌克兰',
    juank_lj:'立即捐款',
    nian:'年',
    yinsi_sm:'隐私声明',
    lianxi_wm:'联系我们',
    lianxi_t1:'如果您有任何其他问题或疑问',
    xinwen:'新聞',
    juanzhu_z:'捐助者',
    guanyu_wm:'關於我們',
    yiban_wt:'一般問題',
    duiyu_mt:'對於媒體',
    duiyu_hz:'合作夥伴',
    sy_xm:'所有項目',
    xiangm_yw_xs:'顯示已完成的項目',
    project_t1:'我们正在寻找愿意帮助我们满足特定需求的制造公司中的合作伙伴',
    project_t2:'成为合作伙伴写信给',
    project_t3:'partnership@u24.gov.ua',
    project_t4:'我们正在寻找愿意帮助我们满足特定需求的制造公司中的合作伙伴。',
    project_t5:'ZEROAGENCY目标是增加对乌克兰的捐款，并确保其分配的效率和透明度。官方平台保护您免受延误、中介和欺诈的侵害。',
    envoy_t1:'泽连斯基总统团结了来自世界各地的舆论领袖来帮助乌克兰。',
    envoy_t2:'演员和导演、音乐家、科学家和运动员齐聚一堂，组成了ZEROAGENCY大使团队。在这个历史转折点上，帮助乌克兰的意愿使他们走到了一起。他们帮助该平台为乌克兰筹集资金，并使其成为国际社会的焦点，不断提醒乌克兰为自由和民主世界的价值观而战。他们的投入是无价的。',
    suoyou_ds:'所有大使',
    jiazai_gd:'加載更多',
    record_input:'按名稱或者關鍵字搜索',
    suoyou_gj:'所有國家/地區',
    juanzheng_zgs:'捐贈者的故事',
    ditu:'地圖',
    ka:"卡",
    suoyou_xw:'所有新聞',
    meiti_bd:'媒体报道',
    ruhe_gz:'他是如何工作的',
    zijin_zz:'资金资助',
    xianshi_qb:'显示全部',
    fangx:'方向',
    xuanze_nyj:"选择您要捐赠的地方",
    jijin_zz:'基金资助',
    xuanze:'选择',
    dfk:'的付款方式',
    juank_srje:'输入捐款金额',
    mingcheng:'名称',
    yinhang_dh:'银行电话',
    yinh_m:'银行名称',
    yinhang_zh:'银行账户',
    yinhang_yh:'银行用户',
    quer_fk:'确认付款',
    shuru_jkr:'输入捐款人名称',
    jiet_sc:'上傳捐款截圖',
    shif_fn:'幫助他們',
    juan_k:'捐款',
    juan_zjl:'捐贈記錄',
    queding:'確定',
    zheng_s:'留下您的信箱以及聯絡地址 我們方便發給您愛心證書 獎杯',
    'Please enter your email address':"請輸入信​​箱",
    "You have subscribed to the latest news":"您已訂閱最新消息"
}
