module.exports={
    "$": "$",
    "head_t1": "Site oficial do governo ucraniano",
    "gengduo_yd": "Leia mais",
    "fenxiang_wz": "compartilhar site",
    "zong_sj": "Valor total da arrecadação",
    "xiangmu": "projeto",
    "dashi": "embaixador",
    "liji_jz": "Doe agora",
    "shipin_cw": "Este vídeo não pode ser reproduzido, tente novamente mais tarde",
    "banner_t1": "Iniciativa do Presidente da Ucrânia",
    "banner_t2": "Plataforma oficial de arrecadação de fundos da Ucrânia",
    "guank_sp": "assista ao vídeo",
    "zhichi_wm": "nossos apoiadores",
    "zuixin_xm": "Últimos projetos",
    "juanz_lx": {
        "0": "defesa",
        "1": "Educação e Ciência",
        "2": "humanitário",
        "3": "assistência médica"
    },
    "suoyou_xm": "Mostrar todos os itens",
    "report_t1": "Transparência e eficiência",
    "report_t2": "ZEROAGENCY fornece relatórios de alocação de fundos em cinco direções todas as semanas",
    "report_t3": "Pessoas de todo o mundo estão unidas e dispostas a ajudar a Ucrânia. ZEROAGENCY visa aumentar as doações para a Ucrânia e garantir eficiência e transparência na sua distribuição.",
    "guanyu_wz": "SOBRE ZEROAGÊNCIA",
    "shouji_zs": "Coleção total",
    "juanz_zj": "Doe diretamente",
    "baogao_qw": "Texto completo do relatório",
    "dashi_wm": "nosso embaixador",
    "suoyou_dsxs": "Mostrar todos os embaixadores",
    "fabu_sj": "Postar nas redes sociais",
    "fenx": "compartilhar",
    "dingy_t1": "Assine a newsletter semanal",
    "dingy_t2": "Assine relatórios, notícias e atualizações do ZEROAGENCY",
    "fenx_p": {
        "0": "Facebook",
        "1": "tubo de óleo",
        "2": "telégrafo"
    },
    "dingye": "subscrição",
    "shuru_yx": "Digite seu e-mail",
    "zuixin_xx": "últimas notícias",
    "suoyou_xwxs": "Mostrar todas as notícias",
    "zhichi_t1": "Apoie a Ucrânia neste momento crítico",
    "juank_lj": "Doe agora",
    "nian": "Ano",
    "yinsi_sm": "Declaração de privacidade",
    "lianxi_wm": "Contate-nos",
    "lianxi_t1": "Se você tiver quaisquer outras dúvidas ou preocupações",
    "xinwen": "notícias",
    "juanzhu_z": "Doador",
    "guanyu_wm": "sobre nós",
    "yiban_wt": "Perguntas gerais",
    "duiyu_mt": "para mídia",
    "duiyu_hz": "Parceiros",
    "sy_xm": "Todos os itens",
    "xiangm_yw_xs": "Mostrar projetos concluídos",
    "project_t1": "Procuramos parceiros em empresas de manufatura que estejam dispostos a nos ajudar a atender às nossas necessidades específicas",
    "project_t2": "Para se tornar um parceiro escreva para",
    "project_t3": "parceria@u24.gov.ua",
    "project_t4": "Procuramos parceiros em empresas de manufatura que estejam dispostos a nos ajudar a atender às nossas necessidades específicas.",
    "project_t5": "ZEROAGENCY visa aumentar as doações para a Ucrânia e garantir eficiência e transparência na sua distribuição. A plataforma oficial protege você contra atrasos, intermediários e fraudes.",
    "envoy_t1": "O Presidente Zelensky reuniu líderes de opinião de todo o mundo para ajudar a Ucrânia.",
    "envoy_t2": "Atores e diretores, músicos, cientistas e atletas se unem para formar a equipe de embaixadores ZEROAGENCY. Neste momento de viragem na história, a vontade de ajudar a Ucrânia uniu-os. Ajudam a plataforma a angariar fundos para a Ucrânia e a colocá-la no centro das atenções internacionais, servindo como um lembrete constante da sua luta pela liberdade e pelos valores de um mundo democrático. A contribuição deles não tem preço.",
    "suoyou_ds": "todos os embaixadores",
    "jiazai_gd": "carregar mais",
    "record_input": "Pesquise por nome ou palavra-chave",
    "suoyou_gj": "Todos os países/regiões",
    "juanzheng_zgs": "Histórias de doadores",
    "ditu": "mapa",
    "ka": "Cartão",
    "suoyou_xw": "Todas as notícias",
    "meiti_bd": "reportagens da mídia",
    "ruhe_gz": "como ele funciona",
    "zijin_zz": "Financiamento",
    "xianshi_qb": "Mostrar tudo",
    "fangx": "direção",
    "xuanze_nyj": "Escolha onde você deseja doar",
    "jijin_zz": "Financiamento",
    "xuanze": "escolher",
    "dfk": "método de pagamento",
    "juank_srje": "Insira o valor da doação",
    "mingcheng": "nome",
    "yinhang_dh": "Número de telefone do banco",
    "yinh_m": "Nome do banco",
    "yinhang_zh": "conta bancária",
    "yinhang_yh": "Usuário do banco",
    "quer_fk": "Confirmar pagamento",
    "shuru_jkr": "Digite o nome do doador",
    "jiet_sc": "Carregar captura de tela de doação",
    "shif_fn": "ajude-os",
    "juan_k": "Doar",
    "juan_zjl": "Registros de doações",
    "queding": "Claro",
    "zheng_s": "Deixe seu e-mail e endereço de contato para que possamos lhe enviar um certificado de amor e um troféu",
    "Please enter your email address": "Por favor insira seu endereço de e-mail",
    "You have subscribed to the latest news": "Você se inscreveu nas últimas notícias"
}